<template>
    <main class="lawnlaying main">
        <h1>Укладка газона</h1>
        <div class="imgs">
            <img v-for="(src, index) in images" :src="src" :key="index" alt="Ливневая канализация" class="img"
                @click="openModal(index)" />
        </div>

        <div v-if="isModalOpen" class="modal" @click="closeModal">
            <div class="modal-content" @click.stop>
                <button v-if="currentIndex > 0" class="prev" @click="prevImage">‹</button>
                <img :src="images[currentIndex]" alt="Enlarged image" class="zoomable-img" ref="zoomImg"
                    @wheel="zoomImage" />
                <button v-if="currentIndex < images.length - 1" class="next" @click="nextImage">›</button>
            </div>
        </div>

        <div class="content">
                <h2>
                    Технология укладки газона
                </h2>
                <ol>
                    Устройство газонов — очень сложный и многоэтапный процесс, требующий большого опыта и сноровки. Чтобы наш клиент был доволен результатом, необходимо выполнить следующие шаги:
                    <li>
                        <b>Планирование:</b> Выполняется оценка участка и определяется необходимое количество материалов (семена, рулоны газона, удобрения, грунт).</li>
                    <li><b>Очистка территории:</b> Удаляются всевозможные сорняки при помощи перекопки или гербицидов. Убирается мусор, камни, старая трава. Этот этап предотвращает конкуренцию между сорняками и новым газоном.</li>
                    <li><b>Выравнивание поверхности:</b> Устраняются все неровности и создается легкий склон для отвода воды. В ситуациях, когда земля окислена, необходимо выполнить перемещение грунта – завести новую землю и вывести старую.</li>
                    <li>
                        <b>Укладка или посев газона:</b> Для рулонного газона выполняем раскатывание рулонов с последующим прижиманием, чтобы газон хорошо прилегал к почве. При посеве семена равномерно распределяются по поверхности почвы с   использованием сеялки или вручную, и вносятся стартовые удобрения. Также  необходимо укатать почву для установления контакта с 
                        семенами и выполнить мульчирование для сохранения влажности и защиты семян от птиц.
                    </li>
                    <li>
                        <b>Полив и уход:</b> Первые несколько недель после укладки газона требуется регулярный полив, 
                        чтобы земля оставалась влажной.
                    </li>
                    <p>При первом выезде инженер-конструктор должен выполнить все
                        необходимые замеры и вычисления для составления проекта канализации
                        и дренажа участка с оптимальными параметрами.
                    </p>
                </ol>
        </div>
    </main>
</template>

<script>
export default {
    data() {
        return {
            isModalOpen: false,
            currentIndex: 0,
            zoomLevel: 1,
            images: [
                require('../../public/images/sistemy/gzn1.png'),
                require('../../public/images/sistemy/gzn2.png'),
                require('../../public/images/sistemy/gzn3.png'),
                require('../../public/images/sistemy/gzn4.png'),
                require('../../public/images/sistemy/gzn5.png')
            ]
        };
    },
    methods: {
        openModal(index) {
            this.currentIndex = index;
            this.isModalOpen = true;
            this.zoomLevel = 1;
        },
        closeModal() {
            this.isModalOpen = false;
        },
        zoomImage(event) {
            event.preventDefault();
            const zoomStep = 0.1;
            if (event.deltaY > 0) {
                this.zoomLevel = Math.max(this.zoomLevel - zoomStep, 1);
            } else {
                this.zoomLevel += zoomStep;
            }
            this.$refs.zoomImg.style.transform = `scale(${this.zoomLevel})`;
        },
        nextImage() {
            if (this.currentIndex < this.images.length - 1) {
                this.currentIndex++;
                this.zoomLevel = 1;
            }
        },
        prevImage() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
                this.zoomLevel = 1;
            }
        }
    }
};
</script>

<style scoped>
.lawnlaying{
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.lawnlaying h1 {
    padding-bottom: 25px;
    color: #c7c6c6;
}
.content {
    width: 55%;
    display: flex;
    flex-direction: column;
    border: 2px solid #c7c6c6;
    padding: 40px;
    border-radius: 4px;
    background-color: #fff3f3;
}
ol{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.imgs {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
}

.img {
    width: 300px;
    height: 200px;
    transition: box-shadow 0.5s ease;
    cursor: pointer;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.img:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    max-width: 90%;
    max-height: 90%;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.zoomable-img {
    max-width: 100%;
    max-height: 100%;
    transition: transform 0.3s ease;
    cursor: zoom-in;
}

.prev,
.next {
    position: absolute;
    top: 50%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 30px;
    transform: translateY(-50%);
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}

.prev:hover,
.next:hover {
    background: rgba(0, 0, 0, 0.8);
}

@media (max-width: 1500px) {
    .img {
        width: calc(20% - 15px);
    }
}

@media (max-width: 1200px) {
    .img {
        width: calc(25% - 15px);
    }

    .content {
        width: 65%;
    }
}

@media (max-width: 900px) {
    .img {
        width: calc(33.33% - 15px);
    }
    .content {
        width: 80%;
    }
}

@media (max-width: 600px) {
    .img {
        width: calc(50% - 15px);
    }
    .content {
        width: 90%;
    }
}

@media (max-width: 400px) {
    .img {
        width: 100%;
    }
}

@media (max-width: 800px) {
    .content {
        gap: 15px;
    }

}

@media (max-width: 500px) {
    .contents {
        width: 100%;
        overflow: hidden;
    }
    .lawnlaying h1 {
        font-size: 1.5em;
    }
}
</style>